<template>
    <div class="app" v-loading="tableLoading">
        <head-layout head-title="系统应用对比分析" :showIcon="false" :head-btn-options="headBtnOptions"
            @head-export="headExport">
        </head-layout>
        <div class="gridTable">
            <el-table :cell-class-name="addClass" class="table_style" :data="tableData" style="width: 100%"
                :header-cell-style="{ textAlign: 'center', }" height="100%" @selection-change="handleSelectionChange">
                <el-table-column prop="projectName" fixed="left" label="项目名称" width="300"></el-table-column>
                <el-table-column v-for="item in tableColData" width="180px" :prop="item.field" :label="item.title"
                    :key="item.name">
                    <el-table-column width="180px" v-for="item1 in item.children" :prop="item1.field"
                        :label="item1.title" :key="item1.id">
                    </el-table-column>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import { mapGetters } from "vuex";
import * as API from "@/api/dbtAppliedTatistics/index";
import { downloadXls } from "@/util/util";

export default {
    components: {
        HeadLayout,
    },
    props: {
        ids: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            tableLoading: false,

            params: {
                currentPage: 1,
                pageSize: 99999999,
                total: 0,
            },
            // 多级表头的数据
            tableColData: [],
            tableData: [],
            multipleSelection: []
        }
    },
    mounted() {
        this.deptId = this.userInfo.dept_id;
        this.onLoad(this.params)
    },
    computed: {
        ...mapGetters(["userInfo"]),
        headBtnOptions() {
            return [
                {
                    label: '导出',
                    emit: "head-export",
                    type: "button",
                    icon: "",
                },
            ];
        },
    },
    methods: {
        // 导出
        headExport() {
            API.importViewPage({
                ...this.params,
                createDept: this.deptId
            }).then(res => {
                console.log(res.data);
                downloadXls(res.data, `系统应用对比分析.xls`);
            })
        },
        // 获取列表数据
        onLoad(params) {
            let that = this;
            this.tableLoading = true;
            API.contrastData({
                ...params,
                createDept: this.deptId,
                ids: this.ids
            }).then((res) => {
                if (res.data.code == 200) {
                    that.tableLoading = false;
                    var records = res.data.data;
                    that.tableColData = records.col
                    that.tableData = records.data;
                } else {
                    that.tableLoading = false;
                }
            });

        },
    }
}
</script>

<style scoped lang="scss">
.app {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .table_style {
        border: 1px solid #EBEEF5;
    }

    .gridTable {
        flex: 1;
        height: calc(100% - 45px);

        .el-pagination {
            padding: 10px;
        }
    }
}
</style>