<template>
    <div class="app" v-loading="tableLoading">
        <head-layout head-title="系统应用对比分析" :showIcon="false" :head-btn-options="headBtnOptions"
            @head-contrast="headContrast" @head-export="headExport">
        </head-layout>
        <!-- <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"></grid-head-layout> -->
        <div class="echartsItem_condition">
            <div class="titleItem">
                <div class="titleItem_lable">项目名称：</div>
                <div class="titleItem_input">
                    <el-input placeholder="请输入项目名称" v-model="searchForm.projectName" clearable>
                    </el-input>
                </div>
            </div>
            <div class="titleItem">
                <div class="titleItem_lable">模块名称：</div>
                <div class="titleItem_input">
                    <el-select v-model="searchForm.moduleName" clearable placeholder="请选择" size="mini">
                        <el-option v-for="item in tableColData" :key="item.title" :label="item.title"
                            :value="item.title">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="titleItem">
                <el-button type="primary" size="mini" icon="el-icon-search" @click="gridHeadSearch"></el-button>
                <el-button @click="gridHeadEmpty" size="mini" icon="el-icon-refresh-right"></el-button>
            </div>
        </div>
        <div class="gridTable">
            <el-table :cell-class-name="addClass" class="table_style" :data="tableData" style="width: 100%"
                :header-cell-style="{ textAlign: 'center', }" height="calc(100% - 52px)"
                @selection-change="handleSelectionChange">
                <el-table-column align="center" type="selection" width="55">
                </el-table-column>
                <el-table-column fixed="left" prop="projectName" label="项目名称" width="300"></el-table-column>
                <el-table-column v-for="item in tableColData" width="180px" :prop="item.field" :label="item.title"
                    :key="item.title">
                    <el-table-column width="180px" v-for="item1 in item.children" :prop="item1.field"
                        :label="item1.title" :key="item1.title">
                    </el-table-column>
                </el-table-column>
            </el-table>
            <el-pagination background align="right" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" :current-page="params.currentPage"
                :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="params.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="params.total">
            </el-pagination>
        </div>
        <el-dialog title="系统应用对比" v-if="dialogVisible" :visible.sync="dialogVisible" width="90%">
            <div style="height: 80vh;">
                <contrast :ids="contrastIds"></contrast>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import contrast from "./contrast.vue";
import HeadLayout from "@/views/components/layout/head-layout";
import { mapGetters } from "vuex";
import * as API from "@/api/dbtAppliedTatistics/index";
import { downloadXls } from "@/util/util";

export default {
    components: {
        HeadLayout,
        contrast
    },
    data() {
        return {
            dialogVisible: false,
            tableLoading: false,
            params: {
                currentPage: 1,
                pageSize: 20,
                total: 0,
            },
            searchForm: {
                projectName: '',
                moduleName: ''
            },
            // 多级表头的数据
            tableColData: [],
            tableData: [],
            multipleSelection: [],
            contrastIds: [],
        }
    },
    mounted() {
        if (this.userInfo.company_id == -1 && !this.userInfo.company_id) {
            this.deptId = this.userInfo.dept_id;

        } else {
            this.deptId = this.userInfo.company_id;
        }
        if (this.deptId == -1) this.deptId = null;
        this.onLoad({ ...this.params, ...this.searchForm });
    },
    computed: {
        ...mapGetters(["userInfo"]),
        headBtnOptions() {
            return [
                {
                    label: '导出',
                    emit: "head-export",
                    type: "button",
                    icon: "",
                },
                {
                    label: '对比',
                    emit: "head-contrast",
                    type: "button",
                    icon: "",
                },
            ];
        },
        searchColumns() {
            return [
                {
                    label: "项目名称",
                    prop: "projectName",
                    span: 3,
                    placeholder: "请输入项目名称",
                },
                {
                    label: "模块名称",
                    prop: "",
                    span: 3,
                    type: 'select',
                    placeholder: "请选择模块名称",
                    dicData: this.selectData,
                    props: {
                        label: 'label',
                        value: 'value',
                    },
                },
                {
                    label: "项目状态",
                    prop: "",
                    span: 3,
                    type: 'select',
                    placeholder: "请选择项目状态",
                    dicData: [
                    ],
                    props: {
                        label: 'label',
                        value: 'value',
                    },
                },
            ];
        },
    },
    methods: {
        // 比较
        headContrast() {
            this.contrastIds = []
            if (this.multipleSelection.length == 0) {
                this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
            } else {
                this.multipleSelection.forEach(item => {
                    this.contrastIds.push(item.projectCode)
                })
                this.dialogVisible = true
            }
        },
        // 导出
        headExport() {
            API.importViewPage({
                ...this.params,
                createDept: this.deptId
            }).then(res => {
                console.log(res.data);
                downloadXls(res.data, `系统应用对比分析.xls`);
            })
        },
        // 每页数量
        handleSizeChange(val) {
            this.params.pageSize = val
            this.onLoad({ ...this.params, ...this.searchForm });
        },
        // 当前页
        handleCurrentChange(val) {
            this.params.currentPage = val
            this.onLoad({ ...this.params, ...this.searchForm });
        },
        // 复选框
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 搜索
        gridHeadSearch() {
            this.onLoad({ ...this.params, ...this.searchForm });
        },
        // 清空
        gridHeadEmpty(searchForm) {
            this.searchForm = {
                projectName: '',
                modelName: ''
            };
            this.deptId = this.userInfo.dept_id;
            this.onLoad({ ...this.params, ...this.searchForm });
        },
        // 获取列表数据
        onLoad(params) {
            let that = this;
            this.tableLoading = true;
            API.contrastData({
                ...params,
                createDept: this.deptId
            }).then((res) => {
                if (res.data.code == 200) {
                    that.tableLoading = false;
                    var records = res.data.data;
                    console.log('数据源', records);
                    that.tableColData = records.col
                    that.tableData = records.data;
                    that.params.total = records.total
                } else {
                    that.tableLoading = false;
                }
            });

        },
    }
}
</script>

<style scoped lang="scss">
.app {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .table_style {
        border: 1px solid #EBEEF5;
    }

    .gridTable {
        flex: 1;
        padding: 0 10px;

        .el-pagination {
            padding: 10px;
        }
    }
}

.echartsItem_condition {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
}

.echartsItem_condition div {
    color: #555 !important;
    font-size: 12px !important;
}

.titleItem {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.titleItem_lable {
    margin-right: 5px;
}

.titleItem_input {
    width: 150px;
}

.titleItem_picker {
    width: 140px;
}

.humanCententTableCentnet {
    margin-top: 15px;
}
</style>